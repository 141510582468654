<template lang="html">
  <div :class="classes">
    <div class="content">
      <register-feedback
        v-if="error"
        v-bind="$attrs"
        v-on="$listeners"
        :error="error"
      />

      <register-form
        v-else
        :token="token"
        v-bind="$attrs"
        v-on="$listeners"
        :form-errors="formErrors"
      />
    </div>

    <div class="aside">
      <div class="right">
        <div class="text-wrapper">
          <p class="title">Olá!</p>

          <p class="text">
            Cadastre o e-mail e senha que você usará para entrar na plataforma.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@components/accountancyRegister/Form'
import RegisterFeedback from '@components/accountancyRegister/Feedback'

import isMS from '@modules/isMS'

export default {
  components: {
    RegisterForm,
    RegisterFeedback
  },

  props: {
    token: String,
    formErrors: Object,
    error: String
  },

  data () {
    return {
      isMS
    }
  },

  computed: {
    componentState () {
      if (this.error) return '-feedback'
      return {
        '-fresh': true,
        '-register': true
      }
    },
    classes () {
      return [ 'desktop-login',
        this.componentState,
        { '-no-animation': this.isMS }
      ]
    }
  }
}
</script>

<style lang="scss">
#app{
  overflow-y: auto;
}

.desktop-login {
  position: relative;
  width: 740px;
  padding-bottom: 40px;
  border-radius: 8px;
  background: linear-gradient(347.66deg, #FFFFFF 0%, rgba(255,255,255,0.83) 100%);
  box-shadow: 0 6px 30px 0 rgba(0,0,0,0.1);
  overflow: hidden;
  align-self: center;

  & > .aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/pattern_login.svg'),
                      set-linear-gradient(diagonal, (light: var(--color-a), dark: var(--color-b)));
    background-blend-mode: overlay;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    border-radius: 8px;

    & > .right {
      width: 280px;
      height: 100%;
      padding: 100px 0px 0px 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      text-align: center;

      & > .text-wrapper {
        border-radius: 15px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        position: relative;

        & > .title {
          margin: 0 -20px;
          font-family: $title-font-family;
          font-size: 28px;
          font-weight: bold;
          line-height: 42px;
          color: #FFFFFF;
          z-index: $z-index-1;
        }

        & > .text {
          margin-top: 17px;
          letter-spacing: $short-letter-spacing;
          line-height: 22px;
          color: #FFFFFF;
          max-width: 100%;
          z-index: $z-index-1;
        }
      }
    }

    & > .right > .text-wrapper::before{
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      filter: blur(20px);
    }

    & > .right > .text-wrapper::before {
      background-image: linear-gradient(135deg, var(--color-a) -80%, var(--color-b) 340%);
    }

  }

  & > .content {
    width: 470px;
    height: 100%;
    padding: 64px 80px 60px 80px;
    transition: margin-left .8s ease-in-out;
  }

  & > .content > .register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > .content > .register-form > .title > .text {
    color: $base-text-color;
    font-weight: bold;
    opacity: .8;
  }

  & > .content > .register-form > .info {
    font-size: 16px;
    letter-spacing: $shorter-letter-spacing;
    line-height: 22px;
    text-align: center;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }

  & > .content > .register-form > .form {
    width: 100%;
    margin-top: 30px;
  }

  & > .content > .register-form > .form > .actions {
    flex-direction: column;
    margin-top: 30px;

    & > .hint { margin: 0 auto; }

    & > .action {
      margin: 0 auto;
      margin-top: 43px;
      width: 200px;
      max-width: 200px;
      height: 60px !important;
      min-height: 60px !important;
      max-height: 60px !important;

      & > .text { font-size: 13px; }
    }
  }

  &.-register {
    & > .content {
      margin-left: 0;
      padding: 64px 80px 20px 80px;

      & > .register-form > .form > .actions > .recaptcha-warning { margin-top: 40px }
    }
    & > .aside {
      animation: aside-backwards .8s;
      animation-fill-mode: both;
    }
    & > .aside > .right {
      transform: translateX(-50px);
    }

  }

  &.-fresh {
    & > .content { transition-property: none }
    & > .aside {
      animation: none;
      clip-path: inset(0 0 0 62%);
    }
  }

  &.-feedback {
    & > .aside { @include hide }
    & > .content {
      width: 100%;
      transition-property: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0px;

      & > .register-form {
        & > .title { margin-bottom: 18px; }
        & > .info {
          max-width: 400px;
          margin-top: 0;
          text-align: center;
          font-size: 16px;
        }
        & > .form > .actions {
          margin-top: 5px;

          & > .action {
            width: 180px;
            margin-top: 0;
          }
        }
      }
    }
  }

  &.-no-animation {
    & > .aside {
      width: calc(100% - 62%);
    }

    &.-register,
    &.-fresh {
      & > .aside {
        left: auto;
        right: 0;

        & > .right { display: none; }
      }
    }

  }

  // animations:
  @keyframes aside-forward {
    0% {
      animation-timing-function: ease-in;
      clip-path: inset(0 0 0 62%);
      background-position-x: 0px, 0px;
    }
    50%  {
      animation-timing-function: ease-out;
      clip-path: inset(0 22% 0 22%);
      background-position-x: -25px , 0px;
    }
    100% {
      clip-path: inset(0 62% 0 0);
      background-position-x: -50px, 0px;
    }
  }

  @keyframes aside-backwards {
    0% {
      animation-timing-function: ease-in;
      clip-path: inset(0 62% 0 0);
      background-position-x: -50px, 0px;
    }
    50%  {
      animation-timing-function: ease-out;
      clip-path: inset(0 22% 0 22%);
      background-position-x: -25px , 0px;
    }
    100% {
      clip-path: inset(0 0 0 62%);
      background-position-x: 0px, 0px;
    }
  }
}
</style>
