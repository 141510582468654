<template lang="html">
  <div :class="classes">
    <c-title
      class="title"
      size="1"
      center
      dark-text
    >
      Cadastre sua contabilidade
    </c-title>

    <c-form
      class="form"
      no-listeners
      key="register"
      :fields="formFields"
      :loading="loadingButton"
      @submit="onSubmit"
    >
      <template slot="actions">

        <div class="token-warning">
          <c-flag alert class="flag" icon="warning-circle" size="18" />
          <p class="text">
            O Token é fornecido pelo RH da
            empresa cliente que compartilhou
            o link de acesso com você.
          </p>
        </div>
        <p class="hint">
          Ao definir minha senha, eu aceito os
          <a class="link" target="_blank" href="https://www.convenia.com.br/termos-de-uso/">
            termos da empresa
          </a>
          e a
          <a class="link" target="_blank" href="https://www.convenia.com.br/politica-de-privacidade/">
            política de serviço.
          </a>
        </p>

        <c-button
          class="action"
          type="submit"
          :disabled="loadingButton"
          :loading="loadingButton"
        >
          cadastrar
        </c-button>

      </template>
    </c-form>
  </div>
</template>

<script>
import squema from './form'

import MediaQuery from '@mixins/MediaQuery'

export default {
  mixins: [ MediaQuery ],

  props: {
    formErrors: Object,

    token: String,

    appType: {
      type: String,
      validator: appType => {
        const types = ['rh', 'bpo']
        return types.includes(appType)
      }
    },

    loginType: {
      type: String,
      validator: appType => {
        const types = ['jwt', 'oAuth2']
        return types.includes(appType)
      }
    },

    loadingButton: Boolean
  },

  computed: {
    formFields () {
      const fields = squema(this)

      if (this.token) {
        fields.token.value = this.token
        fields.token.disabled = true
      }

      return fields
    },

    classes () {
      return ['register-form', {
        '-bpo': this.appType === 'bpo'
      }]
    }
  },

  methods: {
    onSubmit (payload) {
      this.$emit('submit', payload)
    }
  }
}
</script>

<style lang="scss">
.register-form {
  & > .title > .border {
    background: set-linear-gradient(horizontal, (light: var(--color-a), dark:  var(--color-b)));
  }

  @include xs-mobile {
    & > .form > .fields > .field.-jumbo.-validation.password:first-child {
      margin-bottom: 72px;

      & > .validation {
        overflow: unset;

        & > .message > .text {
          overflow: unset;
          white-space: unset;
        }
      }
    }
  }

  & > .form > .actions {
    margin-top: 10px;

    & > .token-warning {
      padding: 15px;
      border-radius: 5px;
      background: rgba(#FFC24B, .2);
      display: flex;
      gap: 20px;
      justify-items: center;
      align-items: center;

      & > .text {
        color: $base-text-color;
        font-size: 14px;
      }
    }

    & > .hint { text-align: center; padding-top: 30px }
    & > .action {
      background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b)));
      & > .border { border-color: var(--color-a) }

      &::before {
        background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b)));
        box-shadow: 0 1px 20px var(--color-a);
      }

      &:hover::before { box-shadow: 0 5px 30px 5px var(--color-a); filter: blur(14px); }
    }

  }

  a {
    border-bottom: 1px dotted;
    font-weight: bold;
    color: $base-text-color;
    letter-spacing: $short-letter-spacing;
    line-height: 22px;
    opacity: .7;

    &:hover { opacity: .8 }
  }

  &.-bpo {
    --color-a: #{map-get($primary-color-map, light)};
    --color-b: #{map-get($primary-color-map, dark)};
  }
}
</style>
