export default {
  signin: {
    err: {
      title: 'Eita, algo deu errado...',
      text: 'Não foi possível fazer o login. Por favor, tente novamente.',
      info: { title: '', text: '' },
      icon: 'erro',
      button: 'Voltar'
    }
  },

  register: {
    err: {
      title: 'Eita, algo deu errado...',
      text: 'Não foi possível fazer o cadatro. Por favor, tente novamente.',
      info: { title: '', text: '' },
      icon: 'erro',
      button: 'Voltar'
    }
  },

  passwordRecovery: {
    success: {
      title: 'E-mail enviado',
      text: 'Enviamos um e-mail com todas as instruções para redefinir a sua senha e continuar usando a plataforma.',
      info: { title: '', text: '' },
      icon: 'enviado',
      button: 'Voltar'
    },
    err: {
      title: 'Eita, algo deu errado...',
      text: 'Não foi possível enviar um e-mail para a redefinição da sua senha. Por favor, tente novamente.',
      info: { title: '', text: '' },
      icon: 'erro',
      button: 'Voltar'
    }
  },

  passwordReset: {
    success: {
      title: 'Sua senha foi alterada',
      text: 'Agora você pode acessar com a nova senha cadastrada e continuar utilizando a plataforma normalmente.',
      info: { title: '', text: '' },
      icon: 'chave',
      button: 'Entrar'
    },
    err: {
      title: 'Eita, algo deu errado...',
      text: 'Não foi possível redefinir a sua senha. Por favor, tente novamente.',
      info: { title: '', text: '' },
      icon: 'erro',
      button: 'Voltar'
    },
    expired: {
      title: 'Solicitação expirada',
      text: 'O link para redefinição de senha não está mais disponível. Faça uma nova solicitação para alterar sua senha.',
      info: { title: '', text: '' },
      icon: 'erro',
      button: 'Alterar senha'
    }
  }
}
