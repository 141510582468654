export default (ctx) => {
  const squema = {
    name: {
      type: 'text',
      label: 'Nome completo',
      validation: {
        required: true
      },
      jumbo: true,
      value: '',
      autocomplete: 'nope',
      ...{ validationMsg: formErrorMessage('name', ctx, 'Digite um nome válido.') },
      ...{ forceError: formError('name', ctx) }
    },
    email: {
      type: 'text',
      label: 'e-mail',
      validation: {
        regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        required: true
      },
      jumbo: true,
      value: '',
      autocomplete: 'nope',
      ...{ validationMsg: formErrorMessage('email', ctx, 'Digite um endereço de e-mail válido.') },
      ...{ forceError: formError('email', ctx) }
    },
    password: {
      type: 'password',
      label: 'senha',
      validation: {
        regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]\^_`{|}~]{6,})$/,
        required: true
      },
      jumbo: true,
      value: '',
      autocomplete: 'new-password',
      ...{ validationMsg: formErrorMessage('password', ctx, 'Sua senha deve ter no mínimo 8 caracteres, utilizando letras maiúsculas, minúsculas e números.') },
      ...{ forceError: formError('password', ctx) }
    },
    passwordConfirmation: {
      type: 'password',
      label: 'repetir senha',
      validation: {
        custom: formData => passwordConfirmation => (formData || {}).password === passwordConfirmation,
        required: true
      },
      jumbo: true,
      value: '',
      autocomplete: 'new-password',
      ...{ validationMsg: formErrorMessage('password_confirmation', ctx, 'Digite a mesma senha.') },
      ...{ forceError: formError('password_confirmation', ctx) }
    },
    accountancyName: {
      type: 'text',
      label: 'nome da sua contabilidade',
      validation: {
        required: true
      },
      jumbo: true,
      value: '',
      autocomplete: 'nope',
      ...{ validationMsg: formErrorMessage('accountancy.name', ctx, 'Digite um nome válido.') },
      ...{ forceError: formError('accountancy.name', ctx) }
    },
    accountancyCNPJ: {
      type: 'text',
      mask: '##.###.###/####-##',
      label: 'CNPJ da sua contabilidade',
      validation: {
        required: true
      },
      jumbo: true,
      value: '',
      autocomplete: 'nope',
      ...{ validationMsg: formErrorMessage('accountancy.cnpj', ctx, 'Digite um CNPJ válido.') },
      ...{ forceError: formError('accountancy.cnpj', ctx) }
    },
    token: {
      type: 'text',
      label: 'token da empresa cliente',
      validation: {
        required: true
      },
      jumbo: true,
      value: '',
      autocomplete: 'nope',
      ...{ validationMsg: formErrorMessage('token', ctx, 'Digite um token válido.') },
      ...{ forceError: formError('token', ctx) }
    }
  }

  return squema
}

function formError (fieldName, ctx) {
  const { formErrors } = ctx || {}
  const dotFormErrors = toDot(formErrors)
  return dotFormErrors && dotFormErrors[`${fieldName}.0`]
}

function formErrorMessage (fieldName, ctx, defaultText) {
  const { formErrors } = ctx
  const dotFormErrors = toDot(formErrors)
  console.log(dotFormErrors)

  if (dotFormErrors && dotFormErrors[`${fieldName}.0`])
    return dotFormErrors[`${fieldName}.0`]

  return defaultText
}

function toDot (object, current = '') {
  if (object && object !== null && typeof object === 'object') {
    return Object.keys(object).reduce((accumulator, key) => {
      const value = object[key]
      const newKey = (current ? current + '.' + key : key)
      const valueIsObject = (value && value !== null && typeof value === 'object')
      return { ...accumulator, ...(valueIsObject ? toDot(value, newKey) : { [newKey]: value }) }
    }, {})
  }
}
