const { appName: n = '', userAgent: a = '' } = navigator || {}

const substr = [
  'internet explorer',
  'trident',
  'msie',
  'edge'
]

export default substr
  .some(str => a.toLowerCase().includes(str) ||
    n.toLowerCase().includes(str)
  )
